$(document).ready(function () {
	"use strict";

//	function loaded() {
		$("#placeholder").fadeOut("fast");
		$("#content").fadeIn("fast");
//	}

	$(".section-link").on('click', function (e) {
		var path = $(this).data("path");
		console.log(path);
		if (path !== "") {
			e.preventDefault();
			$('html, body').animate({
				scrollTop: (path === "top") ? $(document.body).offset().top : $("#" + path).offset().top - 5
			}, 500);
		}
	});

	$(document).scroll(function () {
		if (document.documentElement.scrollTop > 75) {
			$("#backToTop").css("display", "block");
		} else {
			$("#backToTop").css("display", "none");
		}
	});

	$("body").vide({
		mp4: "assets/videos/bg.mp4",
		poster: "assets/img/bg.jpg"
	}, {
		posterType: 'jpg',
		className: 'position-fixed overlay'
	});

	$('[data-toggle="tooltip"]').tooltip();

	function topFunction() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	$("img").each(function(i) {
		var url = $(this).data("src");
		var _this = $(this);
		$.ajax({url: url, cache: true, processData: false, success: function(result){
			_this.attr("src", url).fadeIn();
		}});
	});
});